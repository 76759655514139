import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fluid--panel"
export default class extends Controller {
  static targets = ['content']

  static values = {
    expanded: { type: Boolean, default: false }
  }

  connect() {
    this.expandedValue = false
  }

  expandedValueChanged() {
    this.element.setAttribute('aria-expanded', this.expandedValue)
    this.element.querySelector('.chevron-right').classList.toggle('hidden')
    this.element.querySelector('.chevron-down').classList.toggle('hidden')
    this.contentTarget.classList.toggle('hidden')
  }

  toggle() {
    this.expandedValue = !this.expandedValue
  }

  collapse() {
    this.expandedValue = false
  }

  expand() {
    this.expandedValue = true
  }
}
